body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.hot::-webkit-scrollbar {
  display: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  color: #1d222a;
}
input[type="file"] {
  display: none;
}
.cat {
  display: flex;
  flex-wrap: wrap;
}
.cat label span {
  text-align: center;
  font-size: small;
  border: 1px solid #d7ecf1;
  border-radius: 4px;
  width: auto;
  padding: 4px 15px 4px 15px;
  display: block;
  margin: 0px 0px 20px 30px;
}

.cat label input {
  display: none;
}

.cat label input + span {
  color: white;
}

.cat input:checked + span {
  color: white;
}
input:checked + span {
  background: #eb4335;
  border: 1px solid #eb4335;
}
.artistSide input:checked {
  accent-color: #eb4335;
}
.paystack-button {
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  background-color: #eb4335;
  font-weight: bold;
  color: white;
  border: none;
  border-radius: 5px;
  width: auto;
  padding: 10px;
}

@import "~react-image-gallery/styles/css/image-gallery.css";
